import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

import Layout from '@components/Layout'
import SEO from '@components/SEO'
import Section from '@components/Section'
import NavigationFooter from '@components/Navigation/Navigation.Footer'

import HomeHero from '../sections/home/Home.Hero'
import HomeAbout from '../sections/home/Home.About'
import HomeContact from '../sections/home/Home.Contact'
import HomeTestimonial from '../sections/home/Home.Testimonial'
import HomeServices from '../sections/home/Home.Services'
import HomeWork from '../sections/home/Home.Work'
import HomeBackground from '../sections/home/Home.Background'

import LabsPreview from '../sections/labs/Labs.Preview'
import LabsPreviewMobile from '../sections/labs/Labs.Preview.Mobile'

import media from '@styles/media'

// import HomeTimeline from '../sections/home/Home.Timeline'

/**
 * The home page of Monarck.co!
 */
function IndexPage({ data, location }) {
  const contentful = data.allContentfulPage.edges[0].node
  const {
    carnabyBackground,
    umaBackground,
    uDiscoverBackground,
    designBackground,
  } = data
  const navConfig = {
    offset: true,
    fixed: true,
    theme: 'light',
  }

  // Inlining our products to get the right variables we need in scope!
  const products = [
    {
      logo: NeedlLogo,
      background: uDiscoverBackground.childImageSharp.fluid,
      backgroundColor: '#141419',
      title: 'uDiscover',
      excerpt:
        'This case study will outline how I was able to work with various stakeholders across multiple departments to ultimately architect a solution that executives were extremely satisfied with.',
      children: (
        <>
          <HorizontalRule />
          {/* <FigmaIcon aria-hidden="true" fill="#08080B" /> View in Figma */}
          <div>
            <LinkToProduct
              target="_blank"
              data-a11y="false"
              className="disabled"
              href="/"
            >
              <EyeIcon aria-hidden="true" fill="#fff" />
              Case Study (Coming Soon)
            </LinkToProduct>
          </div>
        </>
      ),
    },
    {
      logo: NeedlLogo,
      background: carnabyBackground.childImageSharp.fluid,
      backgroundColor: '#141419',
      title: 'Rolling Stones | No9 Carnaby St. Store',
      excerpt:
        'This case study will outline how my team was able to turnaround an immersive/highly successful global ecommerce store for the Rolling Stones that will now serve as the foundation for UMG e-commerce sites for years to come.',

      children: (
        <>
          <HorizontalRule />
          <div>
            <LinkToProduct
              target="_blank"
              data-a11y="false"
              className="disabled"
              href="/"
            >
              <EyeIcon aria-hidden="true" fill="#fff" />
              Case Study (Coming Soon)
            </LinkToProduct>
            <LinkToProduct
              target="_blank"
              data-a11y="false"
              href="https://carnaby.therollingstonesshop.com/"
            >
              <GlobeIcon aria-hidden="true" />
              Visit website
            </LinkToProduct>
          </div>
        </>
      ),
    },
    // {
    //   logo: NeedlLogo,
    //   background: designBackground.childImageSharp.fluid,
    //   backgroundColor: '#141419',
    //   title: 'Universal Music Design System',
    //   excerpt: 'Design System excerpt...',
    //   children: (
    //     <>
    //       <HorizontalRule />
    //       {/* <FigmaIcon aria-hidden="true" fill="#08080B" /> View in Figma */}
    //       <div>
    //         <LinkToProduct
    //           target="_blank"
    //           data-a11y="false"
    //           className="disabled"
    //           href="/"
    //         >
    //           <EyeIcon aria-hidden="true" fill="#fff" />
    //           Case Study (Coming Soon)
    //         </LinkToProduct>
    //       </div>
    //     </>
    //   ),
    // },
    // {
    //   logo: NeedlLogo,
    //   background: umaBackground.childImageSharp.fluid,
    //   backgroundColor: '#141419',
    //   title: 'Universal Music Artists App',
    //   excerpt: 'UMA excerpt',
    //   children: (
    //     <>
    //       <HorizontalRule />
    //       {/* <FigmaIcon aria-hidden="true" fill="#08080B" /> View in Figma */}
    //       <div>
    //         <LinkToProduct
    //           target="_blank"
    //           data-a11y="false"
    //           className="disabled"
    //           href="/"
    //         >
    //           <EyeIcon aria-hidden="true" fill="#fff" />
    //           Case Study (Coming Soon)
    //         </LinkToProduct>
    //       </div>
    //     </>
    //   ),
    // },
  ].reverse()

  return (
    <Layout
      nav={navConfig}
      location={location}
      background="#08070B"
      withFooter={false}
    >
      <>
        <SEO
          //   title={contentful.seo.title}
          //   description={contentful.seo.description}
          //   image={contentful.seo.image.file.url}
          title="Rich Rosales | UX Leader"
          description="I’m Rich Rosales, a solution-driven UX leader based in Los Angeles with 15 years of experience conceptualizing and crafting digital products."
          image="opengraph.jpg"
          pathname={location.pathname}
        />
        <HomeHero />
        <HomeAbout />
        <HomeServices />
        {/* <HomeTimeline /> */}
        <HomeBackground />
        <HomeWork />
        <HomeTestimonial />
        <LabsBody>
          <Section narrow>
            {products.map(product => (
              <LabsPreview key={product.excerpt} product={product} />
            ))}
          </Section>
          <Section narrow>
            {products.map(item => (
              <LabsPreviewMobile key={item.excerpt} item={item} />
            ))}
          </Section>
        </LabsBody>
        <HomeContact />
        <GreyBackground>
          <NavigationFooter
            mode="dark"
            to="/articles"
            text="Back to Articles"
          />
        </GreyBackground>
      </>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomePageQuery {
    allContentfulPage(filter: { pageName: { eq: "Home" } }) {
      edges {
        node {
          seo {
            title
            description
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
    carnabyBackground: file(name: { regex: "/labs-carnaby-desktop/" }) {
      childImageSharp {
        fluid(maxWidth: 793, maxHeight: 381, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    umaBackground: file(name: { regex: "/labs-uma-desktop/" }) {
      childImageSharp {
        fluid(maxWidth: 793, maxHeight: 381, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    designBackground: file(name: { regex: "/labs-designsystem-desktop/" }) {
      childImageSharp {
        fluid(maxWidth: 793, maxHeight: 381, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    uDiscoverBackground: file(name: { regex: "/labs-udiscover-desktop/" }) {
      childImageSharp {
        fluid(maxWidth: 793, maxHeight: 381, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const NeedlLogo = () => (
  <svg
    width="141"
    height="21"
    viewBox="0 0 141 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  ></svg>
)

const Mobile = styled.div`
  ${media.phablet`
    display: none;
  `}
`

const HorizontalRule = styled.hr`
  width: 140px;
  height: 1px;
  border: none;
  margin-bottom: 30px;
  background: ${p => (p.dark ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255,255,255,0.1)')};

  ${media.phablet`
    width: 100%;
    margin: 0 auto 25px;
    background: ${p =>
      p.dark ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255,255,255,0.1)'};
  `}
`

const LinkToProduct = styled.a`
  position: relative;
  font-weight: 600;
  font-size: 16px;
  color: ${p => (p.dark ? p.theme.colors.grey : 'rgba(255,255,255,.7)')};
  transition: 0.2s color ease-in-out;
  cursor: pointer;
  &:hover {
    color: #fff;
  }

  svg {
    margin-right: 13px;
  }

  &:nth-child(2) {
    margin-left: 30px;
  }

  &.disabled {
    color: #55556f;
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
    svg {
      opacity: 0.2;
    }
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -10%;
    top: -42%;
    width: 120%;
    height: 200%;
    border: 2px solid ${p => p.theme.colors.purple};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${media.tablet`
    display: block;
    margin: 0 auto;
    color: ${p => (p.dark ? '#000' : '#fff')};

    &:nth-child(2) {
      margin: 15px auto 0;
    }

      svg {
        display: none;
      }
  `}
`

const GreyBackground = styled.div`
  background: #111216;
`
const LabsBody = styled.div`
  background: linear-gradient(180deg, #101216 0%, #08080b 100%);
  position: relative;
  margin-top: -30px;
  z-index: 1;
`

const GlobeIcon = ({ fill = 'white' }) => (
  <svg
    width="19"
    height="14"
    viewBox="0 0 19 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.20896 13.9999C7.34271 14.0075 5.54559 13.2942 4.19251 12.0089C3.99106 11.8171 3.90719 11.5325 3.97251 11.2622C4.03782 10.9919 4.24239 10.7769 4.50915 10.6983C4.77591 10.6197 5.06434 10.6894 5.26579 10.8811C6.33096 11.8879 7.74335 12.445 9.20896 12.4366C9.75609 12.4375 10.3006 12.3616 10.8267 12.2111C11.2297 12.1183 11.6349 12.3572 11.7488 12.7548C11.8627 13.1524 11.6455 13.5697 11.2544 13.7043C10.5898 13.898 9.90125 13.9975 9.20896 13.9999ZM16.3176 8.00353C16.3654 7.67126 16.3888 7.33593 16.3876 7.00024C16.3321 3.08455 13.117 -0.0470396 9.20122 0.000534954C6.45785 -0.00568609 3.94655 1.53903 2.71483 3.99037C2.52583 4.37695 2.68601 4.84355 3.07259 5.03254C3.45918 5.22154 3.92578 5.06136 4.11477 4.67478C5.08411 2.75804 7.05334 1.55361 9.20122 1.5638C12.258 1.51614 14.7767 3.95138 14.8321 7.00802C14.8331 7.26825 14.8149 7.5282 14.7777 7.78576C14.7464 7.99001 14.7978 8.19829 14.9203 8.36463C15.0429 8.53097 15.2266 8.6417 15.431 8.67239H15.5632C15.9535 8.67623 16.2863 8.39008 16.3409 8.00353H16.3176ZM18.5887 9.07689C18.6387 8.88411 18.6545 8.68405 18.6354 8.48581C18.5826 8.06792 18.2068 7.76795 17.7876 7.80917C17.3762 7.86566 17.0817 8.23547 17.1188 8.64913C16.9632 9.23244 13.9456 9.92464 8.81246 8.64913C4.92374 7.66918 2.26385 6.11369 1.65721 5.07929C1.5883 4.98755 1.55264 4.8751 1.5561 4.76041C1.65121 4.35218 1.4057 3.94202 1.00097 3.83297C0.596247 3.72393 0.177909 3.95523 0.0550537 4.35598C-0.0711348 4.8728 0.021739 5.41878 0.311709 5.86481C1.25278 7.45919 4.43376 9.14689 8.42359 10.1502C10.2864 10.6343 12.2001 10.8954 14.1245 10.9279C16.4266 10.9824 18.2387 10.4224 18.5965 9.07689H18.5887Z"
      fill={fill}
    />
  </svg>
)

const EyeIcon = ({ fill = 'white' }) => (
  <svg
    width="20"
    height="12"
    viewBox="0 0 20 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.80781 11.7636C5.89042 11.6782 2.27087 9.65402 0.147117 6.36116C0.0517275 6.2245 0.000394569 6.06195 0 5.89529C0 5.72095 0.0511518 5.55044 0.147117 5.4049C2.209 2.04657 5.86703 0 9.80781 0C13.7486 0 17.4066 2.04657 19.4685 5.4049C19.6536 5.64369 19.6919 5.96513 19.568 6.24072C19.4442 6.5163 19.1784 6.70107 18.8769 6.72115C18.5754 6.74123 18.2874 6.59336 18.1281 6.33664C16.3064 3.48646 13.1893 1.7258 9.80781 1.63706C6.62406 1.71245 3.66329 3.28829 1.82262 5.88711C3.66329 8.48594 6.62406 10.0618 9.80781 10.1372C12.0105 10.1205 14.1393 9.3406 15.8314 7.93041C16.1827 7.6728 16.6743 7.73593 16.949 8.07393C17.2238 8.41193 17.1852 8.90602 16.8613 9.19725C14.8778 10.8429 12.385 11.7498 9.80781 11.7636ZM12.2596 5.7236C12.2596 4.36942 11.1618 3.27165 9.80766 3.27165C9.35627 3.27165 8.99035 3.63757 8.99035 4.08896C8.99035 4.54036 9.35627 4.90628 9.80766 4.90628C10.2591 4.90628 10.625 5.27221 10.625 5.7236C10.625 6.17499 10.2591 6.54092 9.80766 6.54092C9.35627 6.54092 8.99035 6.17499 8.99035 5.7236C8.99035 5.27221 8.62442 4.90628 8.17303 4.90628C7.72164 4.90628 7.35571 5.27221 7.35571 5.7236C7.35571 7.07778 8.45349 8.17555 9.80766 8.17555C11.1618 8.17555 12.2596 7.07778 12.2596 5.7236Z"
      fill={fill}
    />
  </svg>
)

const FigmaIcon = ({ fill = 'white' }) => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5172 3.07248C1.5172 2.04865 2.34845 1.2174 3.37228 1.2174H5.22737V4.92756H3.37228C2.34845 4.92756 1.5172 4.09631 1.5172 3.07248ZM3.37228 0C1.6761 0 0.299805 1.3763 0.299805 3.07248C0.299805 4.08075 0.78612 4.97598 1.53686 5.53629C0.78612 6.0966 0.299805 6.99184 0.299805 8.00011C0.299805 9.00831 0.78606 9.9035 1.53672 10.4638C0.78606 11.0241 0.299805 11.9193 0.299805 12.9275C0.299805 14.6237 1.6761 16 3.37228 16C5.06846 16 6.44476 14.6237 6.44476 12.9275V11.0726V10.4639V10.4637V10.4489C6.96038 10.8403 7.60325 11.0726 8.30012 11.0726C9.99631 11.0726 11.3726 9.69629 11.3726 8.00011C11.3726 6.99184 10.8863 6.09661 10.1355 5.5363C10.8863 4.97598 11.3726 4.08075 11.3726 3.07248C11.3726 1.3763 9.99631 0 8.30012 0H6.44476H5.83634H5.83606H5.22764H3.37228ZM3.37228 6.14502C2.34845 6.14502 1.5172 6.97627 1.5172 8.00011C1.5172 9.0177 2.33834 9.84504 3.35357 9.8551L3.37228 9.85504H5.22736V6.14502H3.37228ZM1.5172 12.9275C1.5172 11.9099 2.33834 11.0826 3.35357 11.0725L3.37228 11.0726H5.22736V12.9275C5.22736 13.9514 4.39611 14.7826 3.37228 14.7826C2.34845 14.7826 1.5172 13.9514 1.5172 12.9275ZM6.44504 4.92756H8.30012C9.32396 4.92756 10.1552 4.09631 10.1552 3.07248C10.1552 2.04865 9.32396 1.2174 8.30012 1.2174H6.44504V4.92756ZM6.44504 8.00011C6.44504 6.97627 7.27629 6.14502 8.30012 6.14502C9.32396 6.14502 10.1552 6.97627 10.1552 8.00011C10.1552 9.02394 9.32396 9.85519 8.30012 9.85519C7.27629 9.85519 6.44504 9.02394 6.44504 8.00011Z"
      fill={fill}
    />
  </svg>
)
