import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import SVG from 'react-inlinesvg'
import Image from '@components/Image'

import media from '@styles/media'

import { AboutRow } from '../../sections/about/About.Team'

export const clientLogosQuery = graphql`
  query GetClientLogosQuery {
    allLogos: file(name: { regex: "/all-logos/" }) {
      publicURL
    }
    appleLogo: file(name: { regex: "/apple/" }) {
      publicURL
    }
    arianaLogo: file(name: { regex: "/ariana/" }) {
      publicURL
    }
    billieLogo: file(name: { regex: "/billie/" }) {
      publicURL
    }
    grizLogo: file(name: { regex: "/griz/" }) {
      publicURL
    }
    kendrickLogo: file(name: { regex: "/kendrick/" }) {
      publicURL
    }
    ladygagaLogo: file(name: { regex: "/ladygaga/" }) {
      publicURL
    }
    odeszaLogo: file(name: { regex: "/odesza/" }) {
      publicURL
    }
    redlightLogo: file(name: { regex: "/redlight/" }) {
      publicURL
    }
    rsLogo: file(name: { regex: "/rollingstones/" }) {
      publicURL
    }
    wbLogo: file(name: { regex: "/wb/" }) {
      publicURL
    }
    weekndLogo: file(name: { regex: "/theweeknd/" }) {
      publicURL
    }
  }
`

function AboutBackground() {
  const {
    allLogos,
    appleLogo,
    arianaLogo,
    billieLogo,
    grizLogo,
    kendrickLogo,
    ladygagaLogo,
    odeszaLogo,
    redlightLogo,
    rsLogo,
    wbLogo,
    weekndLogo,
  } = useStaticQuery(clientLogosQuery)

  return (
    <AboutRow header="Clients">
      <TextContainer>
        <Text>
          I've been fortunate enough to collabrate with hundreds of artists of
          brands throughout my career.
        </Text>
      </TextContainer>
      <Desktop>
        <BrandLogos>
          <Image src={appleLogo.publicURL} />
          <Image src={arianaLogo.publicURL} />
          <Image src={billieLogo.publicURL} />
          <Image src={grizLogo.publicURL} />
          <Image src={kendrickLogo.publicURL} />
          <Image src={ladygagaLogo.publicURL} />
          <Image src={odeszaLogo.publicURL} />
          <Image src={redlightLogo.publicURL} />
          <Image src={rsLogo.publicURL} />
          <Image src={wbLogo.publicURL} />
          <Image src={weekndLogo.publicURL} />
        </BrandLogos>
      </Desktop>
      <Mobile>
        <Image src={allLogos.publicURL} />
      </Mobile>
    </AboutRow>
  )
}

export default AboutBackground

const Desktop = styled.div`
  ${media.phablet`
    display: none;
  `}
`

const Mobile = styled.div`
  display: none;

  ${media.phablet`
    position: relative;
    display: block;
    width:100%;
    margin:20px auto 0;
    img {
        width:100%;
        height:auto;
    }
  `}
`

const TextContainer = styled.div`
  max-width: 681px;
`

const Text = styled.p`
  font-size: 22px;
  line-height: 28px;
  color: #fafafa;

  ${media.tablet`
    font-size: 18px;
  `}
`

const BrandLogos = styled.div`
  margin-top: 80px;
  height: 31px;
  display: flex;
  align-items: center;

  svg,
  img {
    &:not(:last-child) {
      margin-right: 36px;
    }
  }

  img {
    max-height: 25px;
    max-width: 100px;
  }

  ${media.tablet`
    flex-wrap: wrap;
    height: auto;
    margin-top: 45px;

    svg {
      max-height: 21px;
      width: auto;
      margin-bottom: 24px;

      &:not(:last-child) {
        margin-right: 31px;
      }
    }


    #logo-ubisoft {
      max-height: 21.77px;
      order: 1;
    }

    #logo-hopper {
      max-height: 19.18px;
      order: 2;
    }

    #logo-yellowpages {
      max-height: 16.63px;
      order: 3;
    }

    #logo-ritual {
      max-height: 11.28px;
      order: 4;
      margin-right: 30px;
    }

    #logo-unbounce {
      max-height: 18.88px;
      order: 5;
    }

    #logo-breather {
      max-height: 16.2px;
      order: 6;
    }

    #logo-lightspeed {
      max-height: 19.6px;
      order: 7;
      margin-right: 0;
    }
  `}
`
