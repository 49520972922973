import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import { useSpring, animated } from 'react-spring'

import Image from '@components/Image'
import Sticky, { StickyState } from '@components/Sticky'

import media from '@styles/media'
import { clamp, useResize } from '@utils'

import HomeHeading from './Home.Heading'

export const galleryQuery = graphql`
  {
    lightOne: file(name: { regex: "/light-1-weeknd/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    lightTwo: file(name: { regex: "/light-portrait/" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    lightThree: file(name: { regex: "/light-2-portrait/" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    lightFour: file(name: { regex: "/light-1-designsystem/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    darkOne: file(name: { regex: "/dark-1-uma/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    darkTwo: file(name: { regex: "/dark-portrait/" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    darkThree: file(name: { regex: "/dark-2-portrait/" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    darkFour: file(name: { regex: "/dark-1-republic/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

function AboutPhotographs() {
  const SECTION_WIDTH_START = 1140
  const SECTION_WIDTH_END = 1260

  const {
    lightOne,
    lightTwo,
    lightThree,
    lightFour,

    darkOne,
    darkTwo,
    darkThree,
    darkFour,
  } = useStaticQuery(galleryQuery)

  const containerRef = useRef()
  const imageGridRef = useRef()
  const rowOneRef = useRef()
  const rowTwoRef = useRef()
  // const rowThreeRef = useRef()
  const rowFourRef = useRef()

  const [ref, inView] = useInView({ threshold: 0.5 })
  const [sectionHeight, setSectionHeight] = useState(2000)
  const [scale, setScale] = useState(1)
  const { width, height } = useResize()

  const config = { mass: 4, tension: 500, friction: 150 }
  const [propsRowTwo, setRowTwo] = useSpring(() => ({ offset: 1, config }))
  // const [propsRowThree, setRowThree] = useSpring(() => ({ offset: 1, config }))
  const [propsRowFour, setRowFour] = useSpring(() => ({ offset: 1, config }))

  // We want to disable the offset on mobile
  const calcTransform = (offset: number) =>
    width > 768 && `translateY(${offset * 20}vh)`

  const rowTwoStyles = {
    transform: propsRowTwo.offset.interpolate(calcTransform),
  }
  // const rowThreeStyles = {
  //   transform: propsRowThree.offset.interpolate(calcTransform),
  // }
  const rowFourStyles = {
    transform: propsRowFour.offset.interpolate(calcTransform),
  }

  useEffect(() => {
    if (width >= 768) {
      const handleScroll = () => {
        if (imageGridRef.current) {
          const getOffset = (el: HTMLElement) =>
            clamp(
              (el.getBoundingClientRect().top +
                (el.getBoundingClientRect().height / 4) * 2) /
                height,
              0,
              1
            )

          setRowTwo({ offset: getOffset(rowOneRef.current) })
          // setRowThree({ offset: getOffset(rowTwoRef.current) })
          setRowFour({ offset: getOffset(rowTwoRef.current) })
        }
      }

      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [imageGridRef, width])

  useEffect(() => {
    const {
      height: containerHeight,
      width: containerWidth,
    } = containerRef.current.getBoundingClientRect()
    setSectionHeight(containerHeight)

    if (containerWidth > SECTION_WIDTH_START) {
      setScale(
        SECTION_WIDTH_START / clamp(containerWidth, 320, SECTION_WIDTH_END)
      )
    }
  }, [width])

  return (
    <>
      <LightOverlay inView={inView} />
      <AboutWorkContainer>
        <Sticky
          height={`${sectionHeight}px`}
          render={({ progress }: StickyState) => {
            const headingClass = inView ? 'Heading__InView' : ''
            const textClass = inView ? 'Text__InView' : ''
            const firstRowScale = clamp(
              scale + progress * 5 * (1 - scale),
              0,
              1
            )

            return (
              <ImageSection ref={containerRef}>
                <LightSection>
                  <HomeHeading
                    heading={`<span class="${headingClass}">Projects</span>`}
                    text={`<span class="${textClass}">A small curation of some recent projects that I've led and contributed to.
                    </span>`}
                  />
                  <Spacer />
                  <ImageGrid style={{ transform: `scale(${firstRowScale})` }}>
                    <div ref={ref}>
                      {/* <Images ref={rowOneRef}>
                        <ImageWrapper>
                          <StyledImage
                            loading="eager"
                            src={lightOne.childImageSharp.fluid}
                          />
                        </ImageWrapper>
                        <ImageWrapper>
                          <StyledImage
                            loading="eager"
                            src={lightTwo.childImageSharp.fluid}
                          />
                          <ProjectDesc>
                            <Title className="dark">
                              <span>The Weeknd Official Store</span>
                            </Title>
                            <Excerpt>
                              In 2017 I was tasked with co-designing and
                              building The Weeknd's e-commerce store.
                              The same design is still up today and provides
                              sales in the tens of millions each year.
                            </Excerpt>
                            <HorizontalRule />
                            <div>
                              <LinkToProduct
                                target="_blank"
                                data-a11y="false"
                                className="dark"
                                href="https://shop.theweeknd.com"
                              >
                                <GlobeIcon
                                  aria-hidden="true"
                                  className="dark"
                                />
                                Visit website
                              </LinkToProduct>
                            </div>
                          </ProjectDesc>
                        </ImageWrapper>
                      </Images> */}
                      {/* <ImagesMobile style={rowTwoStyles}>
                        <ImageWrapper>
                          <StyledImage
                            loading="eager"
                            src={lightTwo.childImageSharp.fluid}
                          />
                        </ImageWrapper>
                        <ImageWrapper>
                          <StyledImage
                            loading="eager"
                            src={lightThree.childImageSharp.fluid}
                          />
                        </ImageWrapper>
                      </ImagesMobile> */}

                      <animated.div style={rowTwoStyles}>
                        <ImagesReverse ref={rowTwoRef}>
                          <ImageWrapper>
                            <StyledImage
                              loading="eager"
                              src={lightThree.childImageSharp.fluid}
                            />
                            <ProjectDesc>
                              <Title className="dark">
                                <span>Boardriders Design System</span>
                              </Title>
                              <Excerpt>
                              Architected a multi-brand design system to serve Boardriders six primary brands (Quiksilver, Billabong, RVCA, Roxy, DC Shoes & Element). The design system drastically improves maintainability by allowing brands to use a shared set of components themed via brand-specific tokens.

                              </Excerpt>
                              <HorizontalRule />
                              <div>
                                <LinkToProduct
                                  target="_blank"
                                  data-a11y="false"
                                  className="dark disabled"
                                  href="/"
                                >
                                  <GlobeIcon
                                    aria-hidden="true"
                                    className="dark"
                                  />
                                  (In Progress)
                                </LinkToProduct>
                              </div>
                            </ProjectDesc>
                          </ImageWrapper>
                          <ImageWrapper>
                            <StyledImage
                              loading="eager"
                              src={lightFour.childImageSharp.fluid}
                            />
                          </ImageWrapper>
                        </ImagesReverse>
                      </animated.div>
                    </div>
                  </ImageGrid>
                </LightSection>
                <ImageGrid ref={imageGridRef}>

                  <animated.div style={rowFourStyles}>
                    <ImagesReverse ref={rowFourRef}>
                      <ImageWrapper>
                        <StyledImage
                          loading="eager"
                          src={darkThree.childImageSharp.fluid}
                        />
                        <ProjectDesc>
                          <Title>
                            <span>UMG Artist Site Factory</span>
                          </Title>
                          <Excerpt>
                          Co-architected Universal Music's next-gen artist +
                                e-commerce/D2C platform (built in Gatsby). This
                                platform is expected to exceed the 65 million
                                unique visitors and eight figure annual revenue
                                generated from our previous (Drupal) platform. The ASF platform showcases UMG's
                            extensive roster of artists and utilizes various APIs
                            (Youtube, Spotify, BandsinTown, Shopify, etc) to
                            automatically populate content for hundreds of
                            artists.
                          </Excerpt>
                          <HorizontalRule />
                          <div>
                            <LinkToProduct
                              target="_blank"
                              data-a11y="false"
                              href="https://republicrecords.com/"
                            >
                              <GlobeIcon aria-hidden="true" />
                              Visit Demo Site
                            </LinkToProduct>
                          </div>
                        </ProjectDesc>
                      </ImageWrapper>
                      <ImageWrapper>
                        <StyledImage
                          loading="eager"
                          src={darkFour.childImageSharp.fluid}
                        />
                      </ImageWrapper>
                    </ImagesReverse>
                  </animated.div>
                </ImageGrid>
              </ImageSection>
            )
          }}
        />
      </AboutWorkContainer>
    </>
  )
}

export default AboutPhotographs

const LightSection = styled.div`
  position: relative;
  color: #000;
`

const ImageSection = styled.div`
  width: 100%;
  padding: 140px 15px 0px;
  max-width: 1260px;
  margin: 0px auto;
  /* overflow: hidden; */

  ${media.phablet`
    padding: 0px;
  `}
`

const StyledImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`
const AboutWorkContainer = styled.div`
  position: relative;
  z-index: 2;
  padding: 0 0 calc(180px - 10vh);

  ${media.tablet`
    padding: 100px 0 0;
  `}

  .Heading__InView {
    color: #111216;
  }

  .Text__InView {
    color: ${p => p.theme.colors.grey};
  }
`

const LightOverlay = styled.div<{ inView: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: ${p => p.theme.colors.sirius};
  opacity: ${p => (p.inView ? 1 : 0)};
  transition: opacity 1s;
  pointer-events: none;
`

const Spacer = styled.div`
  height: 10vh;
  max-height: 50px;
`

const ImageGrid = styled.div`
  position: relative;
  margin: 15px 0;
  will-change: transform;
  z-index: 2;
  color: #fff;

  ${media.phablet`
    padding: 0 10px;
  `}
`

const Images = styled.div`
  display: grid;
  grid-template-columns: 70fr 30fr;
  grid-template-rows: 1fr;
  grid-gap: 15px;
  margin-bottom: 15px;

  ${media.tablet`
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-bottom: 10px;
  `}
`

const ImagesReverse = styled.div`
  display: grid;
  grid-template-columns: 30fr 70fr;
  grid-template-rows: 1fr;
  grid-gap: 15px;

  ${media.tablet`
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-bottom: 10px;
  `}
`

const ImagesMobile = styled.div`
  display: none;

  ${media.tablet`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 10px;
  `}
`

const ImageWrapper = styled.div<{ hideOnMobile?: boolean }>`
  height: 73.4vh;
  min-height: 500px;
  max-height: 540px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
  position:relative;

  ${media.desktop`
    height: 60vh;
  `}

  ${media.tablet`
    ${p => p.hideOnMobile && `display: none;`}
  `}

  ${media.phablet`
    height: 40vh;
    min-height: 270px;
  `}
`

const HorizontalRule = styled.hr`
  width: 140px;
  height: 1px;
  border: none;
  margin-bottom: 30px;
  background: ${p => (p.dark ? 'rgba(0, 0, 0, 0.1)' : 'rgba(135,135,135,0.3)')};

  ${media.phablet`
    width: 100%;
    margin: 0 auto 25px;
    background: ${p =>
      p.dark ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255,255,255,0.2)'};
  `}
`

const LinkToProduct = styled.a`
  position: relative;
  font-weight: 600;
  font-size: 16px;
  color: ${p => (p.dark ? p.theme.colors.grey : 'rgba(200,200,220,.7)')};
  transition: 0.2s color ease-in-out;
  cursor: pointer;
  &:hover {
    color: #fff;
  }

  &.dark {
    color: ${p => (p.dark ? p.theme.colors.grey : 'rgba(50,50,70,.7)')};
    &:hover {
      color: #222;
    }
  }

  svg {
    margin-right: 13px;
  }

  &:nth-child(2) {
    margin-left: 30px;
  }

  &.disabled {
    color: #55556f;
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
    svg {
      opacity: 0.2;
    }
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -10%;
    top: -42%;
    width: 120%;
    height: 200%;
    border: 2px solid ${p => p.theme.colors.purple};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${media.tablet`
    display: block;
    margin: 0 auto;
    color: ${p => (p.dark ? '#000' : '#fff')};

    &:nth-child(2) {
      margin: 15px auto 0;
    }

      svg {
        display: none;
      }
  `}
`
const Icon = styled.div`
  height: 25px;
  margin-bottom: 25px;
`

const Title = styled.h3`
  margin-bottom: 15px;
  color: inherit;
  max-width: 67%;

  &.dark {
    color: #2f2f33;
  }
  span {
    font-size: 24px;
  }

  ${media.phablet`
    margin-bottom: 25px;
    max-width: 100%;
  `}
`

const ProjectDesc = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 35px 35px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Excerpt = styled.p`
  font-size: 16px;
  color: ${p => p.theme.colors.grey};
  margin-bottom: 30px;

  ${media.phablet`
    margin-bottom: 25px;
  `}
`
const GlobeIcon = ({ fill = 'currentColor' }) => (
  <svg
    width="19"
    height="14"
    viewBox="0 0 19 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.20896 13.9999C7.34271 14.0075 5.54559 13.2942 4.19251 12.0089C3.99106 11.8171 3.90719 11.5325 3.97251 11.2622C4.03782 10.9919 4.24239 10.7769 4.50915 10.6983C4.77591 10.6197 5.06434 10.6894 5.26579 10.8811C6.33096 11.8879 7.74335 12.445 9.20896 12.4366C9.75609 12.4375 10.3006 12.3616 10.8267 12.2111C11.2297 12.1183 11.6349 12.3572 11.7488 12.7548C11.8627 13.1524 11.6455 13.5697 11.2544 13.7043C10.5898 13.898 9.90125 13.9975 9.20896 13.9999ZM16.3176 8.00353C16.3654 7.67126 16.3888 7.33593 16.3876 7.00024C16.3321 3.08455 13.117 -0.0470396 9.20122 0.000534954C6.45785 -0.00568609 3.94655 1.53903 2.71483 3.99037C2.52583 4.37695 2.68601 4.84355 3.07259 5.03254C3.45918 5.22154 3.92578 5.06136 4.11477 4.67478C5.08411 2.75804 7.05334 1.55361 9.20122 1.5638C12.258 1.51614 14.7767 3.95138 14.8321 7.00802C14.8331 7.26825 14.8149 7.5282 14.7777 7.78576C14.7464 7.99001 14.7978 8.19829 14.9203 8.36463C15.0429 8.53097 15.2266 8.6417 15.431 8.67239H15.5632C15.9535 8.67623 16.2863 8.39008 16.3409 8.00353H16.3176ZM18.5887 9.07689C18.6387 8.88411 18.6545 8.68405 18.6354 8.48581C18.5826 8.06792 18.2068 7.76795 17.7876 7.80917C17.3762 7.86566 17.0817 8.23547 17.1188 8.64913C16.9632 9.23244 13.9456 9.92464 8.81246 8.64913C4.92374 7.66918 2.26385 6.11369 1.65721 5.07929C1.5883 4.98755 1.55264 4.8751 1.5561 4.76041C1.65121 4.35218 1.4057 3.94202 1.00097 3.83297C0.596247 3.72393 0.177909 3.95523 0.0550537 4.35598C-0.0711348 4.8728 0.021739 5.41878 0.311709 5.86481C1.25278 7.45919 4.43376 9.14689 8.42359 10.1502C10.2864 10.6343 12.2001 10.8954 14.1245 10.9279C16.4266 10.9824 18.2387 10.4224 18.5965 9.07689H18.5887Z"
      fill={fill}
    />
  </svg>
)

const EyeIcon = ({ fill = 'white' }) => (
  <svg
    width="20"
    height="12"
    viewBox="0 0 20 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.80781 11.7636C5.89042 11.6782 2.27087 9.65402 0.147117 6.36116C0.0517275 6.2245 0.000394569 6.06195 0 5.89529C0 5.72095 0.0511518 5.55044 0.147117 5.4049C2.209 2.04657 5.86703 0 9.80781 0C13.7486 0 17.4066 2.04657 19.4685 5.4049C19.6536 5.64369 19.6919 5.96513 19.568 6.24072C19.4442 6.5163 19.1784 6.70107 18.8769 6.72115C18.5754 6.74123 18.2874 6.59336 18.1281 6.33664C16.3064 3.48646 13.1893 1.7258 9.80781 1.63706C6.62406 1.71245 3.66329 3.28829 1.82262 5.88711C3.66329 8.48594 6.62406 10.0618 9.80781 10.1372C12.0105 10.1205 14.1393 9.3406 15.8314 7.93041C16.1827 7.6728 16.6743 7.73593 16.949 8.07393C17.2238 8.41193 17.1852 8.90602 16.8613 9.19725C14.8778 10.8429 12.385 11.7498 9.80781 11.7636ZM12.2596 5.7236C12.2596 4.36942 11.1618 3.27165 9.80766 3.27165C9.35627 3.27165 8.99035 3.63757 8.99035 4.08896C8.99035 4.54036 9.35627 4.90628 9.80766 4.90628C10.2591 4.90628 10.625 5.27221 10.625 5.7236C10.625 6.17499 10.2591 6.54092 9.80766 6.54092C9.35627 6.54092 8.99035 6.17499 8.99035 5.7236C8.99035 5.27221 8.62442 4.90628 8.17303 4.90628C7.72164 4.90628 7.35571 5.27221 7.35571 5.7236C7.35571 7.07778 8.45349 8.17555 9.80766 8.17555C11.1618 8.17555 12.2596 7.07778 12.2596 5.7236Z"
      fill={fill}
    />
  </svg>
)
