import React from 'react'
import styled from 'styled-components'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Heading from '@components/Heading'
import SVG from 'react-inlinesvg'

import Section from '@components/Section'
import media from '@styles/media'

const articlesQuotesQuery = graphql`
  query GetArticleQuotes {
    allContentfulArticleQuote {
      edges {
        node {
          article {
            slug
          }
          company
          largeIcon {
            file {
              url
            }
          }
          smallIcon {
            file {
              url
            }
          }
          quote {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
      }
    }
  }
`

function HomeQuote() {
  const {
    allContentfulArticleQuote: { edges: quotes },
  } = useStaticQuery(articlesQuotesQuery)

  const quote = quotes[0].node
  //   const blockquote = quote.quote.childMarkdownRemark.rawMarkdownBody

  return (
    <Gradient>
      <Grid>
        {/* <SVG src={quote.largeIcon.file.url} /> */}
        <EducationHeading>
          {/* In
          <br />
          Depth */}
        </EducationHeading>
        <div>
          {/* <Blockquote>Purdue University</Blockquote>
          <StyledSpan>
            <GradIcon aria-hidden="true" data-a11y="false" />
            B.S. Computer Graphics, December 2006
          </StyledSpan> */}
          <Blockquote>Case Studies</Blockquote>
          <StyledSpan>
            An in-depth overview of my thought process on some of my recent
            projects
          </StyledSpan>
        </div>
      </Grid>
    </Gradient>
  )
}

export default HomeQuote

const EducationHeading = styled(Heading.h2)`
  position: relative;
  text-align: right;
  margin-top: px;
  color: ${p => p.theme.colors.grey};
`

const Gradient = styled.div`
  padding: 100px 0 80px;
  background: linear-gradient(180deg, #08080b 0%, #101216 100%);
  /* background: linear-gradient(180deg, #101216 0%, #08080b 100%); */

  ${media.tablet`
    padding: 50px 0 115px;
  `}
`

const Grid = styled(Section)`
  position: relative;
  display: grid;
  grid-template-columns: 132px 675px;
  grid-column-gap: 131px;

  ${media.tablet`
    display: block;

    & > span > svg {
      width: 94.29px;
      height: 25px;
      margin-bottom:15px;
    }
  `}
`

const Blockquote = styled.blockquote`
  font-family: ${p => p.theme.fontfamily.serif};
  font-style: italic;
  font-size: 36px;
  color: #fafafa;
  margin-bottom: 20px;

  a {
    color: ${p => p.theme.colors.gold};

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  ${media.tablet`
    font-size: 24px;
    margin: 20px 0 45px;
  `}
`

const StyledSpan = styled.span`
  display: inline-flex;
  align-items: center;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.4);
  transition: color 0.3s var(--ease-out-quad);

  svg {
    margin-right: 13px;
    opacity: 0.2;
  }

  &:not(:last-child) {
    margin-right: 60px;
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -10%;
    top: -50%;
    width: 120%;
    height: 200%;
    border: 2px solid ${p => p.theme.colors.purple};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${media.tablet`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    padding: 15px 0;


    &:not(:last-child) {
      margin-right: 0;
      border-bottom: 1px solid rgba(255,255,255,0.25)};
    }
  `}
`

const GradIcon = () => (
  <svg
    fill="none"
    stroke="#fff"
    viewBox="0 0 24 24"
    width="30"
    height="27"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 14l9-5-9-5-9 5 9 5z" />
    <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
    />
  </svg>
)
