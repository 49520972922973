import React, { useContext, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'

import ButtonArrow from '@components/Button/Button.Arrow'
import Heading from '@components/Heading'
import Section from '@components/Section'
import Image from '@components/Image'
import ScrollIndicator from '@components/ScrollIndicator'
// import ShapeShifter from '@components/ShapeShifter'
import IntersectionObserver from '@components/IntersectionObserver'
import Transitions from '@components/Transitions'
import LayoutHeroMobile from '@components/Layout/Layout.Hero.Mobile'
import Pill from '@components/Pill'
import { ContactContext } from '@components/Contact/Contact.Context'
import RRHeaderTop from '../../assets/home/illustrations/header-top.svg'
import RRHeaderBottom from '../../assets/home/illustrations/header-bottom.svg'

import media from '@styles/media'

function HomeHero() {
  const { toggleContact } = useContext(ContactContext)
  // Start the bulb up animation once the image has laoded
  const [animateBulb, setBulbAnimation] = useState(false)

  return (
    <LayoutHeroMobile>
      <HomeHeroContainer id="home-hero">
        <Section>
          <IntersectionObserver
            render={({ intersectionRatio: ir }) => (
              <ContentContainer
                style={ir ? { opacity: ir * ir } : { opacity: 1 }}
              >
                <TextContainer>
                  <Transitions.CSS.FadeIn>
                    <Pill text="👋Hello" />
                    <Heading.h1>
                      I’m Rich Rosales, a solution-driven UX leader based in Los
                      Angeles with 14 years of experience conceptualizing and
                      crafting digital products.
                    </Heading.h1>
                    <MainText>
                      I specialize in product design and strategy — wireframing,
                      UI visual design, design systems and front-end
                      development. Currently serving as
                      the Global UI/UX Director @ Boardriders.
                    </MainText>
                    <LinkToResume
                      target="_blank"
                      data-a11y="false"
                      href="/RichRosales-UXLeader-2022.pdf"
                    >
                      Download Résumé
                    </LinkToResume>
                    {/* <MainText>
                      When I'm not staring at a screen I'm honing my photography
                      skills, looking to level up my sneaker collection,
                      practicing my DJ skills or triple-putting at the golf
                      course,
                    </MainText> */}
                    {/* <Contact onClick={toggleContact}>
                      Press <Key>C</Key> anywhere to contact us.
                    </Contact> */}
                    {/* <Mobile>
                      <ButtonArrow
                        as="button"
                        onClick={toggleContact}
                        text="Get in touch"
                      />
                    </Mobile> */}
                  </Transitions.CSS.FadeIn>
                </TextContainer>
                {/* <img
                  src={RRHeader}
                  alt="Rich Rosales"
                  width="100%"
                  height="auto"
                /> */}
                <ImageContainer>
                  <HeroImageTop start={animateBulb}>
                    <Image
                      onLoad={() => setBulbAnimation(true)}
                      src={RRHeaderTop}
                    />
                  </HeroImageTop>
                  <HeroImageBottom>
                    <Image src={RRHeaderBottom} />
                  </HeroImageBottom>
                </ImageContainer>
              </ContentContainer>
            )}
          />
          <ScrollIndicator />
        </Section>
      </HomeHeroContainer>
    </LayoutHeroMobile>
  )
}

export default HomeHero

const HomeHeroContainer = styled.div`
  ${media.desktop`
    #mirror-mask {
      display: none;
  `}
`

const LinkToResume = styled.a`
  font-size: 1.7rem;
  font-weight: bold;
  padding: 0.7rem 2.4rem;
  border-radius: 50px;
  border: 2px solid #fff;
  color: #fff;
  margin-top: 15px;
  display: inline-block;
  transition: 0.2s background ease-in-out;
  &:hover {
    background: #fff;
    color: #000;
  }
`

const TextContainer = styled.div`
  position: relative;
  z-index: 10;
  max-width: 570px;
  top: 10px;

  ${media.phablet`
    position: relative;
  `}
`

const MainText = styled.p`
  font-size: 2rem;
  font-weight: 400;
  color: ${p => p.theme.colors.grey};
  line-height: 1.3;
  margin: 1rem 0;

  ${media.phablet`
    font-size: 1.6rem;
  `};
`

const ContentContainer = styled.div`
  height: calc(100vh - 230px);
  min-height: 600px;
  padding-top: 10px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.phablet`
    height: calc(100vh - 180px);
    min-height: 100%;
    padding: 0;
    top: -40px;
  `};

  ${media.desktop_medium`
    min-height: 360px;
  `};

  @media screen and (max-height: 800px) {
    min-height: 360px;
  }

  @media screen and (max-height: 648px) {
    top: -60px;
  }
`

const Contact = styled.button`
  color: ${p => p.theme.colors.grey};
  font-weight: 600;
  transition: color 0.25s ease;

  &:hover {
    color: #fff;

    span {
      background: #fff;
    }
  }

  ${media.desktop`
    display: none;
  `};
`

const Key = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 1px;
  color: #000;
  background: ${p => p.theme.colors.grey};
  font-size: 13px;
  width: 16px;
  height: 16px;
  border-radius: 2.5px;
  transition: background 0.25s ease;
`

const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  width: 460px;
  right: 0vw;
  justify-self: flex-end;

  ${media.desktop_medium`
    right: 2%;
  `};

  ${media.desktop`
    display:  ${p => (p.desktop ? ' none' : 'block')};
    right: 0;
    left: auto;
    position: relative;
    margin: 0 auto;
    width: 100%;
    position: absolute;
    bottom: 0;
    max-width: 200px;
    padding: 0;
    margin: 0;
  `};
`

const HeroImageTop = styled.div`
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  left: -30px;
  top: 20px;
  transform: translateY(${p => (p.start ? '0px' : '-10px')}) rotate(-10deg);
  transition: transform 1.4s var(--ease-in-out-cubic);
  ${media.tablet`
    /* max-width: 40px; */
    transform: none;
  `}

  ${p =>
    p.start &&
    css`
      animation: ${float} 2s ease-in-out infinite 1.4s;
    `}
`

const float = keyframes`
  0%, 100% {
    transform: translatey(0px) rotate(-10deg);
  }

  50% {
    transform: translatey(-10px) rotate(-10deg);
  }
`

const HeroImageBottom = styled.div<{ start: boolean }>`
  max-width: 600px;
  margin: 0 auto;
`

const Mobile = styled.div`
  ${media.desktop_medium_up`
    display: none;
  `};
`
