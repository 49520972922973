import React, { useState, useRef, createRef, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import usePortal from 'react-useportal'
import { useStaticQuery, graphql } from 'gatsby'
import SVG from 'react-inlinesvg'
import OutsideClickHandler from 'react-outside-click-handler'

import Heading from '@components/Heading'
import Section from '@components/Section'
import Sticky, { StickyState } from '@components/Sticky'
import Image from '@components/Image'

import media from '@styles/media'
import { bodyScroll, useResize, getBreakpointFromTheme } from '@utils'

import { ExIcon, ChevronRightIcon, ChevronLeftIcon } from '../../icons/ui/index'
import { IGraphqlSharpFluidImage, IStaticImage } from '../../types/index'

import AboutTeamModal from './About.Team.Modal'

interface Person {
  name: string
  role: string
  illustrationInactive: IGraphqlSharpFluidImage
  illustration: IGraphqlSharpFluidImage
  signature: IStaticImage
  about: string[]
  social: string[]
}

export const illustrationQuery = graphql`
  query GetIllustrations {
    web: file(name: { regex: "/web-active/" }) {
      childImageSharp {
        fluid(maxWidth: 1170, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    webInactive: file(name: { regex: "/web-inactive/" }) {
      childImageSharp {
        fluid(maxWidth: 1170, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    purdue: file(name: { regex: "/purdue-active/" }) {
      childImageSharp {
        fluid(maxWidth: 1170, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    purdueInactive: file(name: { regex: "/purdue-inactive/" }) {
      childImageSharp {
        fluid(maxWidth: 1170, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    myspace: file(name: { regex: "/myspace-active/" }) {
      childImageSharp {
        fluid(maxWidth: 1170, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    myspaceInactive: file(name: { regex: "/myspace-inactive/" }) {
      childImageSharp {
        fluid(maxWidth: 1170, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    interscope: file(name: { regex: "/interscope-active/" }) {
      childImageSharp {
        fluid(maxWidth: 1170, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    interscopeInactive: file(name: { regex: "/interscope-inactive/" }) {
      childImageSharp {
        fluid(maxWidth: 1170, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    breatherLogo: file(name: { regex: "/company-logo-breather/" }) {
      publicURL
    }
    umg: file(name: { regex: "/umg-active/" }) {
      childImageSharp {
        fluid(maxWidth: 1170, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    umgInactive: file(name: { regex: "/umg-inactive/" }) {
      childImageSharp {
        fluid(maxWidth: 1170, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

function AboutTeam() {
  const illustrations = useStaticQuery(illustrationQuery)

  const people: Person[] = [
    {
      name: `Hello World`,
      role: `1999-2003`,
      illustrationInactive: illustrations.webInactive,
      illustration: illustrations.web,
      about: [
        `I was able to grow my interest for web design into a passion after enrolling in my high school's vocational web design class. In that class, we learned all of the essential tools necessary to run all fo the website properties for the township. I was appointed the head webmaster by my senior year in 2002. That same year, we built a site that won various national accolades by the Business Professionals of America. In 2003 I graduated with Honors - third in my class with a 4.54 GPA.`,
      ],
    },

    {
      name: `Purdue University`,
      role: `2003-2006`,
      illustrationInactive: illustrations.purdueInactive,
      illustration: illustrations.purdue,
      about: [
        `I continued onward with this passion thanks to a full-ride scholarship to Purdue's Computer Graphics program for my undergraduate education. In that track, I learned a lot about the theory and principles behind UX and was able to secure back-to-back Summer internships at Firestone in Indianapolis and eventually a Summer internship at 20th Century Fox's Mobile division in Los Angeles my senior year (2006). I was able to evolve my graphic, animation and development skills immensely due to these internships and the great mentors they provided along the way.`,
      ],
    },

    {
      name: `MySpace`,
      role: `2007-2008`,
      illustrationInactive: illustrations.myspaceInactive,
      illustration: illustrations.myspace,
      about: [
        `Fortunately, my internship at Fox Mobile allowed me to get my foot in the door at MySpace, another 20th Century Fox property. There, I designed and developed advertising campaigns and profiles for dozens of Fortune 500 companies, recording artists, and entertainment agencies in order to maximize consumer engagement for upcoming film releases. Eventually, I served as primary point of contact for the development and implementation of rich media advertisements across MySpace.`,
      ],
    },
    {
      name: `Interscope Records`,
      role: `2008-2016`,
      illustrationInactive: illustrations.interscopeInactive,
      illustration: illustrations.interscope,
      about: [
        `My passion for music eventually guided me to Interscope, where as part of a small design/development team and eventually worked my way into spearheading the design, development and maintenance of 150+ artist sites and stores. I collaborated with marketing and engineering teams to build a robust Drupal ‘artist site factory’ platform, which still serves as the foundation for hundreds of engaging sites, stores and digital marketing campaigns.`,
      ],
    },
    {
      name: `Universal Music`,
      role: `2016-Present`,
      illustrationInactive: illustrations.umgInactive,
      illustration: illustrations.umg,
      about: [
        `I currently serve as the Director of User Experience at Universal Music, where I lead front-end development and UX for their proprietary artist platform that serves over 450 artist sites and stores. I work with a small team of designers/developers to create various feature sets and components within the platform which generates over $200 million in annual revenue (a 900% increase in D2C sales since I began the position in 2016). I also collaborated with dozens of stakeholders on marketing/e-commerce initiatives to promote a data-driven design culture`,
      ],
    },
  ]

  const [childRef, setChildRef] = useState()
  const [modalRef, setModalRef] = useState()
  const cardsRef = useRef()
  const cardRefs = useRef([...Array(people.length)].map(() => createRef()))

  const [selectedPersonIndex, setSelectedPersonIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [horizontalOffset, setHorizontalOffset] = useState(0)

  const { Portal } = usePortal()
  const { width } = useResize()

  const isDesktop = width > 540
  const person = people[selectedPersonIndex]

  function handleRef(ref: any) {
    setChildRef(ref)
  }

  function handleModalRef(ref: any) {
    setModalRef(ref)
  }

  function handleSetSelectedPersonIndex(index: number) {
    if (cardRefs.current[index]) {
      setSelectedPersonIndex(index)

      const el: React.RefObject<any> = cardRefs.current[index]
      const box = el.current.getBoundingClientRect()

      if (childRef.current) {
        childRef.current.style.top = `${box.y}px`
        childRef.current.style.left = `${box.x}px`
      }
    }
  }

  function handleModalToggle(open: boolean, index?: number) {
    if (typeof index === 'number') {
      handleSetSelectedPersonIndex(index)
    }

    if (open) {
      bodyScroll('disable', modalRef.current)
    } else {
      document.body.style.pointerEvents = 'none'

      setTimeout(() => {
        document.body.style.pointerEvents = ''
        bodyScroll('enable')
      }, 460)
    }

    setIsOpen(open)
  }

  useEffect(() => {
    function handleEscKeyPress(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        handleModalToggle(false)
        document.body.style.pointerEvents = ''
        bodyScroll('enable')
      }
    }

    window.addEventListener('keydown', handleEscKeyPress, true)
    return () => window.removeEventListener('keydown', handleEscKeyPress, true)
  }, [modalRef])

  useEffect(() => {
    if (cardsRef.current) {
      const GAP_BETWEEN_CARDS = 80

      const $cards = cardsRef.current
      const { width: cardsWidth } = $cards.getBoundingClientRect()
      const { width: cardWidth } = $cards.firstChild.getBoundingClientRect()

      let offset = cardsWidth - cardWidth

      if (width <= 768) {
        offset = cardsWidth - cardWidth - cardWidth + GAP_BETWEEN_CARDS - 30
      }

      if (width <= 540) {
        offset = 20
      }

      if (width >= 1024) {
        offset = cardsWidth - cardWidth - cardWidth - GAP_BETWEEN_CARDS
      }

      setHorizontalOffset(offset)
    }
  }, [cardsRef, cardRefs, width])

  return (
    <>
      <AboutTeamContainer>
        {isDesktop ? (
          <Sticky
            cover
            height="2222px"
            // height="1200px"
            render={({ progress: prog }: StickyState) => {
              return (
                <Section narrow>
                  <Title>
                    <span>Career Timeline</span>
                  </Title>
                  <TeamCardsContainer
                    style={{
                      transform: `translate3d(-${prog *
                        horizontalOffset}px, 0, 0)`,
                      width: `${horizontalOffset}px`,
                    }}
                  >
                    <Cards ref={cardsRef}>
                      {people.map((person, index) => {
                        const cardIsOpen =
                          isOpen && selectedPersonIndex === index

                        return (
                          <Card
                            isOpen={isOpen}
                            key={person.name}
                            ref={cardRefs.current[index]}
                            isSelected={selectedPersonIndex === index}
                            onClick={() => handleModalToggle(true, index)}
                          >
                            <IllustrationColored isOpen={cardIsOpen}>
                              <Image
                                src={person.illustration.childImageSharp.fluid}
                                loading="eager"
                              />
                            </IllustrationColored>
                            <Illustration isOpen={cardIsOpen}>
                              <Image
                                src={
                                  person.illustrationInactive.childImageSharp
                                    .fluid
                                }
                                loading="eager"
                              />
                            </Illustration>
                            <div style={{ position: 'relative' }}>
                              <Name isOpen={cardIsOpen}>{person.name}</Name>
                              <Role isOpen={cardIsOpen}>
                                {person.role} <ArrowIcon />
                              </Role>
                            </div>
                          </Card>
                        )
                      })}
                    </Cards>
                  </TeamCardsContainer>
                  <Progress>
                    <Value
                      style={{ transform: `translate3d(${prog * 437}%, 0, 0)` }}
                    />
                  </Progress>
                </Section>
              )
            }}
          />
        ) : (
          <Section narrow>
            {people.map((person, index) => {
              const cardIsOpen = isOpen && selectedPersonIndex === index

              return (
                <Card
                  isOpen={isOpen}
                  key={person.name}
                  ref={cardRefs.current[index]}
                  isSelected={selectedPersonIndex === index}
                  onClick={() => handleModalToggle(true, index)}
                >
                  <IllustrationColored isOpen={cardIsOpen}>
                    <Image
                      src={person.illustration.childImageSharp.fluid}
                      loading="eager"
                    />
                  </IllustrationColored>
                  <Illustration isOpen={cardIsOpen}>
                    <Image
                      src={person.illustrationInactive.childImageSharp.fluid}
                      loading="eager"
                    />
                  </Illustration>
                  <div style={{ position: 'relative' }}>
                    <Name isOpen={cardIsOpen}>{person.name}</Name>
                    <Role isOpen={cardIsOpen}>
                      {person.role} <ArrowIcon />
                    </Role>
                  </div>
                </Card>
              )
            })}
          </Section>
        )}
        <AboutRow header="Extra-curricular Activities">
          <TextContainer>
            <Text>
              When I'm not staring at a screen, I like to find inspiration by
              taking on any freelance 💻 UX consulting gigs, getting lost in a
              🎧 DJ set (music has always been a big part of me), leveling up my
              👟 sneaker collection, honing my 📷 photography skills, 🏖️
              traveling with my family or ⛳ triple-putting for bogies out on
              the links.
            </Text>
          </TextContainer>
        </AboutRow>
      </AboutTeamContainer>

      {/*

      The way the modal works for this is we have two parts.
      1. The Framer Motion animation that makes it look like the component is
          moving as a single piece
      2. The Modal content which is placed on top of the Framer Motion black modal

      */}
      <Portal>
        <AboutTeamModal isSelected={isOpen} handleRef={handleRef} />
        <AboutTeamModalContent
          isOpen={isOpen}
          people={people}
          person={person}
          handleModalRef={handleModalRef}
          handleOutsideClick={
            isOpen ? () => handleModalToggle(false) : () => {}
          }
          handleSetSelectedPersonIndex={handleSetSelectedPersonIndex}
        />
      </Portal>
    </>
  )
}

export function AboutRow({
  children,
  header,
}: {
  children: React.ReactNode
  header: string
}) {
  return (
    <AboutRowSpacer data-scroll-fade={true}>
      <Section narrow>
        <AboutRowContainer>
          <AboutRowHeader>{header}</AboutRowHeader>
          <AboutRowContent>{children}</AboutRowContent>
        </AboutRowContainer>
      </Section>
    </AboutRowSpacer>
  )
}

function AboutTeamModalContent({
  isOpen,
  people,
  person,
  handleModalRef,
  handleOutsideClick,
  handleSetSelectedPersonIndex,
}: {
  isOpen: boolean
  people: Person[]
  person: Person
  handleModalRef: () => void
  handleOutsideClick: () => void
  handleSetSelectedPersonIndex: (index: number) => void
}) {
  const [buttonPlacement, setButtonPlacement] = useState({ top: 0, right: 0 })
  const modalRef = useRef()
  const modalScrollRef = useRef()
  const { width } = useResize()

  useEffect(() => {
    handleModalRef(modalRef)
  }, [])

  useEffect(() => {
    const { top, left } = modalRef.current.getBoundingClientRect()
    const tablet = getBreakpointFromTheme('tablet')

    if (width > tablet) {
      setButtonPlacement({})
    } else {
      setButtonPlacement({
        top: `${top + 30}px`,
        left: `${left + 20}px`,
      })
    }
  }, [isOpen, modalRef, setButtonPlacement, width])

  const modalStyles = isOpen
    ? { opacity: 1, transition: `opacity 0s ease 0.4s` }
    : { opacity: 0, pointerEvents: 'none' }

  const activeIndex = people.findIndex(p => p.name === person.name)

  let nextIndex = activeIndex + 1
  if (activeIndex === people.length - 1) {
    nextIndex = 0
  }

  let prevIndex = activeIndex - 1
  if (activeIndex === 0) {
    prevIndex = people.length - 1
  }

  useEffect(() => {
    if (isOpen) {
      function handleKeyDown(event: KeyboardEvent) {
        switch (event.key) {
          case 'ArrowLeft':
            activeIndex === 0
              ? handleSetSelectedPersonIndex(people.length - 1)
              : handleSetSelectedPersonIndex(activeIndex - 1)
            break
          case 'ArrowRight':
            activeIndex === people.length - 1
              ? handleSetSelectedPersonIndex(0)
              : handleSetSelectedPersonIndex(activeIndex + 1)
            break
          default:
            return
        }
      }

      window.addEventListener('keydown', handleKeyDown)
      return () => window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen, activeIndex])

  return (
    <Modal style={modalStyles}>
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <ModalContent ref={modalRef}>
          <CloseButton
            onClick={handleOutsideClick}
            style={buttonPlacement}
            isOpen={isOpen}
          >
            <ExIcon fill="#fff" />
          </CloseButton>
          {isOpen && (
            <ModalGrid key={person.name}>
              <ModalAbout>
                <ModalName>{person.name}</ModalName>
                <ModalRole>{person.role}</ModalRole>
                <div>
                  {person.about.map((text, index) => (
                    <ModalText key={text} index={index}>
                      {text}
                    </ModalText>
                  ))}
                </div>
              </ModalAbout>
              <MediaAnimator>
                <Image src={person.illustration.childImageSharp.fluid} />
              </MediaAnimator>
            </ModalGrid>
          )}
          <ModalPrev
            onClick={() => handleSetSelectedPersonIndex(prevIndex)}
            isOpen={isOpen}
          >
            <ChevronLeftIcon />
          </ModalPrev>
          <ModalNext
            onClick={() => handleSetSelectedPersonIndex(nextIndex)}
            isOpen={isOpen}
          >
            <ChevronRightIcon />
          </ModalNext>
        </ModalContent>
      </OutsideClickHandler>
    </Modal>
  )
}

export default AboutTeam

const fadeInAndUp = keyframes`
  from { opacity: 0; transform: translateY(14px); }
  to { opacity: 1; transform: translateY(0); }
`

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const Title = styled.h3`
  margin-bottom: 15px;
  -webkit-background-clip: text;
  background-clip: text;
  background-repeat: no-repeat;
  background-image: linear-gradient(92.07deg, #bfa8a7 0.99%, #adbbd2 93.48%);
  background-size: cover;
  color: transparent;
  font-family: ${p => p.theme.fontfamily.serif};

  span {
    font-size: 48px;
    text-align: center;
    display: block;
    color: transparent;
  }
`

const AboutTeamContainer = styled.div`
  /* padding: 0 0 240px; */
  padding:0;

  /* ${media.desktop`
    padding: 0 0 200px;
  `}

  ${media.tablet`
    padding: 0 0 160px;
  `}

  ${media.phablet`
    padding: 0 0 90px;
  `} */
`

const AboutRowSpacer = styled.div`
  overflow: hidden;
`

const AboutRowContainer = styled.div`
  display: flex;

  ${media.desktop`
    flex-direction: column;
  `};
`

const AboutRowHeader = styled(Heading.h2)`
  align-self: flex-start;
  font-size: 3.2rem;
  color: ${p => p.theme.colors.grey};
  width: 20rem;
  min-width: 20rem;
  line-height: 1.4;
  padding-bottom: 1rem;
  margin-right: 6.3rem;

  ${media.desktop`
    flex-direction: column;
    margin: 0 0 3.5rem 0;
  `};

  ${media.tablet`
    padding-bottom: 0;
    margin-bottom: 1rem;
    width: 100%;
    font-size: 2.4rem;
  `};
`

const AboutRowContent = styled.div`
  flex: 1;
`

const TextContainer = styled.div`
  display: grid;
  /* grid-template-columns: 261px 361px; */
  grid-template-columns: 660px 361px;
  grid-column-gap: 57px;

  ${media.tablet`
    display: block;
    max-width: 355px;
  `}
`

const Text = styled.p`
  font-size: 22px;
  line-height: 28px;
  color: #fafafa;

  ${media.tablet`
    margin-bottom: 20px;
  `}

  ${media.phablet`
    font-size: 18px;
  `}
`

const TeamCardsContainer = React.memo(styled.div`
  display: flex;
  color: ${p => p.theme.colors.grey};
  width: 20rem;
  min-width: 20rem;
  width: 100%;
  padding: 50px 0 0 100px;
  align-items: center;
  will-change: transform;
  transform-style: preserve-3d;

  ${media.desktop`
    padding: 50px 0 0 0;
  `}
`)

const Cards = React.memo(styled.div`
  display: grid;
  grid-template-columns: repeat(5, 320px);
  grid-column-gap: 20px;

  ${media.phablet`
    grid-template-columns: repeat(5, 90vw);
    grid-column-gap: 10px;
  `}
`)

const Illustration = styled.div<{ isOpen: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: ${p => (p.isOpen ? 0 : 1)};
  transition: opacity 0.4s;
  will-change: opacity;
  ${media.phablet`
    img {
        transform:translateY(-100px);
    }
  `}
`

const IllustrationColored = styled.div<{ isOpen: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: ${p => (p.isOpen ? 0 : 1)};
  transition: ${p => (p.isOpen ? 'opacity 0.4s 0.3s' : 'opacity 0.3s;')};
  will-change: opacity;
  ${media.phablet`
    img {
        transform:translateY(-100px);
    }
  `}
`

const Name = styled(Heading.h2)<{ isOpen: boolean }>`
  margin-bottom: 0;
  transform: translateY(${p => (p.isOpen ? 0 : -8)}px);
  opacity: ${p => (p.isOpen ? 0 : 1)};
  transition: transform 0.4s 0.5s, opacity 0.4s 0.5s;
`

const Role = styled.div<{ isOpen: boolean }>`
  display: inline-block;
  position: relative;
  color: ${p => p.theme.colors.grey};
  font-size: 22px;
  transform: translateY(${p => (p.isOpen ? 0 : -8)}px);
  opacity: ${p => (p.isOpen ? 0 : 1)};
  transition: transform 0.5s 0.475s, opacity 0.4s 0.475s, color 0.4s;

  svg {
    position: absolute;
    right: -24px;
    top: 30%;
    transform: translateY(-30%);
    opacity: 0;
    transform: translateX(-3px);
    transition: opaicty 0.3s, transform 0.3s ease;
  }
`

const ArrowIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00008 2.66602L7.06008 3.60602L10.7801 7.33268H2.66675V8.66602H10.7801L7.06008 12.3927L8.00008 13.3327L13.3334 7.99935L8.00008 2.66602Z"
      fill="#E9DAAC"
    />
  </svg>
)

const Card = styled.div<{ isSelected: boolean; isOpen: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 470px;
  background: #000;
  box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  padding: 0 0 44px;
  cursor: pointer;
  will-change: filter;
  transition: filter 0.2s, opacity 0s;
  overflow: hidden;
  opacity: ${p => (p.isSelected && p.isOpen ? 0 : 1)};

  ${p => !p.isOpen && `transition-delay: 0.4s;`}

  &:hover svg {
    opacity: 1;
    transform: translateX(0);
  }

  &:hover ${Role} {
    color: ${p => p.theme.colors.gold};
  }

  &:hover ${Illustration} {
    opacity: 0;
  }

  ${media.tablet`
    height: 66vh;
    max-height: 550px;
    padding: 0 0 30px;
  `}

  ${media.phablet`
    height: 410px;
    margin-bottom: 20px;

    &:first-child {
      margin-top: 40px
    }

    &:last-child {
      margin-bottom: 70px
    }
  `}

  ${media.phone_small`
    height: 380px;
  `}
`

const Progress = styled.div`
  margin: 40px 15px 180px 263px;
  position: relative;
  height: 1px;
  background: rgba(250, 250, 250, 0.15);
  overflow: hidden;

  ${media.desktop`
    margin: 40px 0 180px;
  `}

  ${media.tablet`
    margin: 40px 0 20vh;
  `}
`

const Value = styled.div`
  position: absolute;
  left: 0;
  width: 18.6%;
  height: 1px;
  background: ${p => p.theme.colors.sirius};
`

const CloseButton = styled.button<{ isOpen: boolean }>`
  position: absolute;
  top: 30px;
  left: 30px;
  height: 24px;
  width: 24px;
  z-index: 1;
  opacity: ${p => (p.isOpen ? 1 : 0)};
  transition: opacity 0.4s ease 0.4s;

  ${media.phablet`
    position: fixed;

    &::before {
      content: '';
      position: absolute;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background: black;
      border-radius: 50%;
      z-index: -1;
    }
  `}
`

const Modal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 20px;

  & > div {
    flex: 1;
    max-width: 1140px;
    max-height: 630px;
    height: 100%;
  }

  ${media.phablet`
    padding: 30px 15px;

    & > div {
      max-height: 92vh;
      max-height: calc(var(--vh, 1vh) * 92);
    }
  `}
`

const ModalContent = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1140px;
  height: 100%;
  width: 100%;
  background: #000;
  border-radius: 5px;
  box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.2);

  ${media.tablet`
    overflow-y: scroll;
    max-height: 92vh;
    max-height: calc(var(--vh, 1vh) * 92);
    -webkit-overflow-scrolling: touch;
  `}
`

const ModalGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ModalAbout = styled.div`
  width: 100%;
  max-width: 520px;
  margin: 40px 0 0 75px;
  max-height: 92vh;
  max-height: calc(var(--vh, 1vh) * 92);
  -webkit-overflow-scrolling: touch;

  ${media.desktop_small`
    max-width: initial;
    padding: 40px 80px;
    margin: 0 auto;
  `}

  ${media.tablet`
    padding: 30px;
  `}

  ${media.phablet`
    padding: 80px 20px;
    pointer-events: none;
  `}

`

const ModalText = styled.p<{ index: number }>`
  color: #fff;
  margin-bottom: 25px;
  opacity: 0;
  animation: ${fadeInAndUp} 1.15s cubic-bezier(0.165, 0.84, 0.44, 1)
    ${p => p.index * 100 + 300}ms forwards;

  ${media.phablet`
    margin-bottom: 20px;
  `}
`

const ModalName = styled(Heading.h2)`
  margin-bottom: 5px;
  opacity: 0;
  animation: ${fadeInAndUp} 1.15s cubic-bezier(0.165, 0.84, 0.44, 1) 200ms
    forwards;
`

const ModalRole = styled.div`
  font-size: 22px;
  color: ${p => p.theme.colors.grey};
  margin-bottom: 30px;
  opacity: 0;
  animation: ${fadeInAndUp} 1.15s cubic-bezier(0.165, 0.84, 0.44, 1) 300ms
    forwards;

  ${media.phablet`
    margin-bottom: 20px;
  `}
`

const MediaAnimator = styled.div`
  width: 472px;
  margin-left: 35px;
  flex: 1;
  opacity: 0;
  animation: ${fadeIn} 1s cubic-bezier(0.165, 0.84, 0.44, 1) 300ms forwards;

  ${media.desktop_small`
    display: none;
  `}
`

const ModalNext = styled.button<{ isOpen: boolean }>`
  position: absolute;
  right: -20px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  background: #fff;
  border-radius: 50%;
  opacity: ${p => (p.isOpen ? 1 : 0)};
  transform: translate(${p => (p.isOpen ? 0 : -8)}px, -50%);
  transition: all 0.4s ease 0.4s;

  svg {
    transition: transform 0.2s ease;
  }

  &:hover svg {
    transform: translateX(2px);
  }

  &:hover svg {
    background: #fafafa;
  }

  opacity: 0;
  animation: ${fadeIn} 1s cubic-bezier(0.165, 0.84, 0.44, 1) 100ms forwards;

  ${media.desktop_medium`
    right: -10px;
  `}

  ${media.tablet`
    display: none;
  `}
`

const ModalPrev = styled.button<{ isOpen: boolean }>`
  position: absolute;
  left: -20px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  background: #fff;
  border-radius: 50%;
  opacity: ${p => (p.isOpen ? 1 : 0)};
  transform: translate(${p => (p.isOpen ? 0 : 8)}px, -50%);
  transition: all 0.4s ease 0.4s;

  svg {
    transition: transform 0.2s ease;
  }

  &:hover svg {
    transform: translateX(-2px);
  }

  &:hover svg {
    background: #fafafa;
  }

  opacity: 0;
  animation: ${fadeIn} 1s cubic-bezier(0.165, 0.84, 0.44, 1) 100ms forwards;

  ${media.desktop_medium`
    left: -10px;
  `}

  ${media.tablet`
    display: none;
  `}
`

const Desktop = styled.div`
  ${media.phablet`
    display: none;
  `}
`

const Mobile = styled.div`
  display: none;
  margin: 50px auto 80px;

  ${media.phablet`
    position: relative;
    display: block;
  `}
`
